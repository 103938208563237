import { Box, Card, CardHeader, Grid, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons';
import { cssVar } from 'utils/css';

const PREFIX = 'DashboardSupport';

const classes = {
  button: `${PREFIX}Button`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.button}`]: {
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid var(--color-ebb)',
    borderRadius: '5px',
    paddingTop: '30px',
    paddingBottom: '30px',
    color: cssVar('--primary'),
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid var(--primary)',
    },
  },
}));

export function DashboardSupport() {
  const { t } = useTranslation();

  return (
    <StyledCard>
      <CardHeader title={t('recent_support_tickets')} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <button
              className={classes.button}
              onClick={() => {
                window.open(
                  'https://support.rocket.net/hc/en-us/requests/new',
                  '_blank',
                  'noreferrer'
                );
              }}
              type="button"
            >
              <Box pb={2}>
                <FontAwesomeIcon icon={faPlus} size="2x" />
              </Box>
              <Box>{t('create_ticket')}</Box>
            </button>
          </Grid>
          <Grid item xs={6}>
            <button
              className={classes.button}
              onClick={() => {
                window.open('https://support.rocket.net', '_blank', 'noreferrer');
              }}
              type="button"
            >
              <Box pb={2}>
                <FontAwesomeIcon icon={faEnvelopeOpen} size="2x" />
              </Box>
              <Box>{t('view_tickets')}</Box>
            </button>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
}
