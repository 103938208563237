import { Box, Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DashboardMetrics } from 'component/partial/DashboardMetrics';
import { DashboardSupport } from 'component/partial/DashboardSupport';
import { DashboardUpdates } from 'component/partial/DashboardUpdates';
import { SiteList } from 'component/partial/SiteList';

export function Dashboard({ isWpSiteControl }: { readonly isWpSiteControl: boolean }) {
  const { t } = useTranslation();

  return (
    <main id="mainContent">
      <Container maxWidth="lg">
        <Box marginBottom={3}>
          <Typography variant="h1" data-testid="domainLabel">
            {t('dashboard')}
          </Typography>
        </Box>
        <DashboardMetrics />
        <SiteList isWpSiteControl={isWpSiteControl} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <DashboardSupport />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardUpdates />
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
