import { useDashboardFeed } from 'api/dashboard';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Link,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

export function DashboardUpdates() {
  const { data } = useDashboardFeed();
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t('rocket_updates')} />
      <CardContent>
        <List>
          {data?.data?.items?.map((item, index) => {
            if (index > 2) {
              return null;
            }
            return (
              <ListItem key={index}>
                <div>
                  <Box display="block">
                    <Link target="_blank" rel="noreferrer" href={item.url}>
                      {item.title}
                    </Link>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      {format(new Date(item.date_modified), 'EEEE, LLLL do, yyyy')}
                    </Typography>
                  </Box>
                </div>
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
}
